.home-div{
    position: relative;
    display: flex;
    width: 100vw;
    height: 60vh;
    overflow: hidden;
}

.home-div img{
    width: 100vw;
    height: auto;
}

.name-h{
    position: absolute;
    z-index: 5;
    top: 80px;
    color: white;
    margin-left: 20px;
}