.main-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: calc(100vh);
    overflow-y: auto;
    overflow-x: hidden;
}

.main-div::-webkit-scrollbar{
    display: none;
}

.main-content-div{
    display: flex;
}

.footer-div{
    width: 100vw;
    height: fit-content;
}

.map-div{
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 15px 20px;
    background-color: #2c507b;
    color: white;
}

.footer-menu-each{
    width: calc((100vw - 40px)/4);
    text-align: center;
}

.footer-menu-each a{
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-decoration: none;
}

.category-name{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}

.category-name a{
    margin-right: 10px;
}

.undercategory-div{
    transform: scale(1);
    transition: transform ease-in 0.2s;
}

.undercategory-div a{
    font-size: 16px;
    color: white;
    text-decoration: none;
    font-weight: normal;
}

.undercategory-div:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: transform ease-in 0.2s;
}

.social-media-full{
    display: flex;
}

.social-media{
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.social-media img{
    width: 20px;
    margin-right: 10px ;
}

.social-media a{
    color: white;
}

.copyright-div{
    text-align: center;
    height: fit-content;
    padding: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.596);
    background-color: #1d3b5f;
}

.contacts{
    display: flex;
    align-items: center;
}

@media (max-width: 600px){
    .main-div{
        height: calc(100vh - 80px);
    }

    .footer-menu-each{
        width: calc((100vw - 60px)/2);
        margin-right: 10px;
    }

    .map-div{
        width: 100vw;
        flex-wrap: wrap;
    }
}

